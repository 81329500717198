import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 8-8-8-8\\@70% 1RM`}</p>
    <p>{`&`}</p>
    <p>{`Strict Pullups 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`50-Calorie Row`}</p>
    <p>{`40-Ring Dips`}</p>
    <p>{`30-Calorie Row`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`10-Calorie Row`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`20-Ring Rows`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`40-Ring Rows`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      